import React from 'react';
import Image from '../../../utils/OptimizedImage';
import {
  Content,
  ContentWrapper,
  Heading,
  Kicker,
  ReferenceWrapper,
  Subhead,
  TestimonialWrapper,
} from '../ConversionPanelStyles';

const ConversionTestimonial = ({
  conversionButton,
  convTestimonial,
  heading,
  headingKicker,
  size,
  subhead,
  theme,
  variant,
}) => (
  <ContentWrapper>
    <Content align="left">
      {headingKicker && <Kicker theme={theme}>{headingKicker}</Kicker>}
      {heading && (
        <Heading theme={theme} size={size} variant={variant}>
          {heading}
        </Heading>
      )}
      {subhead && (
        <Subhead dangerouslySetInnerHTML={{ __html: subhead }} theme={theme} />
      )}
      {conversionButton}
    </Content>
    <ReferenceWrapper>
      {convTestimonial && (
        <Subhead dangerouslySetInnerHTML={{ __html: convTestimonial?.body }} />
      )}
      {convTestimonial?.person && (
        <TestimonialWrapper>
          {convTestimonial?.person?.headshot ? (
            <Image
              image={convTestimonial?.person.headshot.image.gatsbyImageData}
              src={convTestimonial?.person.headshot.image.url}
              alt={`Artistic Interpretation (Avatar) of ${convTestimonial?.person?.firstName} ${convTestimonial?.person?.lastName}`}
            />
          ) : (
            <span className="letter-avatar">
              {convTestimonial?.person?.firstName &&
                convTestimonial?.person?.firstName[0]}
              {convTestimonial?.person?.lastName &&
                convTestimonial?.person?.lastName[0]}
            </span>
          )}
          <div className="customer-info">
            {(convTestimonial?.person?.firstName ||
              convTestimonial?.person?.lastName) && (
              <p className="name">
                {convTestimonial?.person.firstName}{' '}
                {convTestimonial?.person.lastName}
              </p>
            )}
            {convTestimonial?.person?.role && (
              <span className="role">{convTestimonial?.person.role}</span>
            )}
          </div>
        </TestimonialWrapper>
      )}
    </ReferenceWrapper>
  </ContentWrapper>
);

export default ConversionTestimonial;
